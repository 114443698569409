var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _vm._m(0),
    _c("div", { staticClass: "content2" }, [
      _c("div", { staticClass: "title" }, [_vm._v("智能柜表面颜色可定制")]),
      _c("div", { staticClass: "gzBox" }, [
        _c("div", { staticClass: "gz_action" }, [
          _c("ul", [
            _c(
              "li",
              {
                on: {
                  click: function($event) {
                    return _vm.onclick1(1)
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "bk1 bk",
                  on: {
                    click: function($event) {
                      return _vm.onclick1(1)
                    }
                  }
                }),
                _c("div", { class: _vm.active1 == 1 ? "txt c1" : "txt" }, [
                  _vm._v("蓝色")
                ])
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function($event) {
                    return _vm.onclick1(2)
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "bk2 bk",
                  on: {
                    click: function($event) {
                      return _vm.onclick1(2)
                    }
                  }
                }),
                _c("div", { class: _vm.active1 == 2 ? "txt c3" : "txt" }, [
                  _vm._v("灰色")
                ])
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function($event) {
                    return _vm.onclick1(3)
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "bk3 bk",
                  on: {
                    click: function($event) {
                      return _vm.onclick1(3)
                    }
                  }
                }),
                _c("div", { class: _vm.active1 == 3 ? "txt c2" : "txt" }, [
                  _vm._v("绿色")
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "gz" }, [
          _c("ul", [
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A01")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A02")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A03")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A04")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A05")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A06")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A07")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A08")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A09")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A10")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A11")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A12")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A13")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A14")])]
              )
            ]),
            _c("li", [
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "left bk1"
                      : _vm.active1 == 2
                      ? "left bk2"
                      : "left bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A15")])]
              ),
              _c(
                "div",
                {
                  class:
                    _vm.active1 == 1
                      ? "right bk1"
                      : _vm.active1 == 2
                      ? "right bk2"
                      : "right bk3"
                },
                [_c("div", { staticClass: "number" }, [_vm._v("A16")])]
              )
            ])
          ]),
          _vm._m(1)
        ]),
        _vm._m(2)
      ])
    ]),
    _c("div", { staticClass: "content3" }, [
      _c("div", { staticClass: "title" }, [_vm._v("三种常见柜型选择")]),
      _vm.active3 == 3
        ? _c("img", {
            staticClass: "img",
            attrs: { src: require("../../../assets/cabinet/content4_1.png") }
          })
        : _vm._e(),
      _vm.active3 == 1
        ? _c("img", {
            staticClass: "img",
            attrs: { src: require("../../../assets/cabinet/content4_2.png") }
          })
        : _vm._e(),
      _vm.active3 == 2
        ? _c("img", {
            staticClass: "img",
            attrs: { src: require("../../../assets/cabinet/content4_3.png") }
          })
        : _vm._e(),
      _c("div", { staticClass: "action" }, [
        _c("ul", [
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.onclick2(1)
                }
              }
            },
            [
              _c("div", { staticClass: "img_logo" }, [
                _c("div", { staticClass: "cabinetBox" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      class: _vm.active3 == 1 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 1 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      class: _vm.active3 == 1 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 1 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      class: _vm.active3 == 1 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 1 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", {
                    staticClass: "row",
                    staticStyle: { height: "6px" }
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      class: _vm.active3 == 1 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 1 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      class: _vm.active3 == 1 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 1 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      class: _vm.active3 == 1 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 1 ? "right bk3" : "right bk2"
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("p", { class: _vm.active3 == 1 ? "title3 c2" : "title3" }, [
                  _vm._v("标准柜(中间)")
                ]),
                _c("p", { staticClass: "describe" }, [
                  _vm._v("1980 x 900 x 500")
                ])
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.onclick2(2)
                }
              }
            },
            [
              _c("div", { staticClass: "img_logo" }, [
                _c("div", { staticClass: "cabinetBox" }, [
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 2 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 2 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 2 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 2 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 2 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 2 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 2 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 2 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 2 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 2 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 2 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 2 ? "right bk3" : "right bk2"
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("p", { class: _vm.active3 == 2 ? "title3 c2" : "title3" }, [
                  _vm._v("标准柜")
                ]),
                _c("p", { staticClass: "describe" }, [
                  _vm._v("1980 x 900 x 500")
                ])
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.onclick2(3)
                }
              }
            },
            [
              _c("div", { staticClass: "img_logo img_logo1" }, [
                _c("div", { staticClass: "cabinetBox cabinetBox1" }, [
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 3 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 3 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 3 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 3 ? "right bk3" : "right bk2"
                    })
                  ]),
                  _c("div", { staticClass: "row1" }, [
                    _c("div", {
                      class: _vm.active3 == 3 ? "left bk3" : "left bk2"
                    }),
                    _c("div", {
                      class: _vm.active3 == 3 ? "right bk3" : "right bk2"
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("p", { class: _vm.active3 == 3 ? "title3 c2" : "title3" }, [
                  _vm._v("标准矮柜")
                ]),
                _c("p", { staticClass: "describe" }, [
                  _vm._v("1150 x 900 x 500")
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../../assets/cabinet/cabinet_logo.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer_bottom" }, [
      _c("div", { staticClass: "line_bottom" }),
      _c("div", { staticClass: "line_bottom" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }