<template>
  <div class="index">
    <div class="banner">
      <img src="../../../assets/cabinet/cabinet_logo.png" class="img" />
    </div>
    <div class="content2">
      <div class="title">智能柜表面颜色可定制</div>
      <div class="gzBox">
        <div class="gz_action">
          <ul>
            <li @click="onclick1(1)">
              <div class="bk1 bk"  @click="onclick1(1)"></div>
              <div :class="active1 == 1 ? 'txt c1' : 'txt'">蓝色</div>
            </li>
            <li @click="onclick1(2)">
              <div class="bk2 bk"  @click="onclick1(2)"></div>
              <div :class="active1 == 2 ? 'txt c3' : 'txt'">灰色</div>
            </li>
            <li @click="onclick1(3)">
              <div class="bk3 bk"  @click="onclick1(3)"></div>
              <div :class="active1 == 3 ? 'txt c2' : 'txt'">绿色</div>
            </li>
          </ul>
        </div>
        <div class="gz">
          <ul>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A01</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A02</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A03</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A04</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A05</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A06</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A07</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A08</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A09</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A10</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A11</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A12</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A13</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A14</div>
              </div>
            </li>
            <li>
              <div :class="active1 == 1 ? 'left bk1' : active1 == 2 ? 'left bk2' : 'left bk3'">
                <div class="number">A15</div>
              </div>
              <div :class="active1 == 1 ? 'right bk1' : active1 == 2 ? 'right bk2' : 'right bk3'">
                <div class="number">A16</div>
              </div>
            </li>
          </ul>
          <div class="footer">
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="line_bottom"></div>
          <div class="line_bottom"></div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="title">三种常见柜型选择</div>
      <img v-if="active3 == 3" src="../../../assets/cabinet/content4_1.png" class="img" />
      <img v-if="active3 == 1" src="../../../assets/cabinet/content4_2.png" class="img" />
      <img v-if="active3 == 2" src="../../../assets/cabinet/content4_3.png" class="img" />
      <div class="action">
        <ul>
          <li @click="onclick2(1)">
            <div class="img_logo">
              <div class="cabinetBox">
                <div class="row">
                  <div :class="active3 == 1 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 1 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row">
                  <div :class="active3 == 1 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 1 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row">
                  <div :class="active3 == 1 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 1 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row" style="height: 6px"></div>
                <div class="row">
                  <div :class="active3 == 1 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 1 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row">
                  <div :class="active3 == 1 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 1 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row">
                  <div :class="active3 == 1 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 1 ? 'right bk3' : 'right bk2'"></div>
                </div>
              </div>
            </div>
            <div class="desc">
              <p :class="active3 == 1 ? 'title3 c2' : 'title3'">标准柜(中间)</p>
              <p class="describe">1980 x 900 x 500</p>
            </div>
          </li>
          <li @click="onclick2(2)">
            <div class="img_logo">
              <div class="cabinetBox">
                <div class="row1">
                  <div :class="active3 == 2 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 2 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 2 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 2 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 2 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 2 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 2 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 2 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 2 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 2 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 2 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 2 ? 'right bk3' : 'right bk2'"></div>
                </div>
              </div>
            </div>
            <div class="desc">
              <p :class="active3 == 2 ? 'title3 c2' : 'title3'">标准柜</p>
              <p class="describe">1980 x 900 x 500</p>
            </div>
          </li>
          <li @click="onclick2(3)">
            <div class="img_logo img_logo1">
              <div class="cabinetBox cabinetBox1">
                <div class="row1">
                  <div :class="active3 == 3 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 3 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 3 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 3 ? 'right bk3' : 'right bk2'"></div>
                </div>
                <div class="row1">
                  <div :class="active3 == 3 ? 'left bk3' : 'left bk2'"></div>
                  <div :class="active3 == 3 ? 'right bk3' : 'right bk2'"></div>
                </div>
              </div>
            </div>
            <div class="desc">
              <p :class="active3 == 3 ? 'title3 c2' : 'title3'">标准矮柜</p>
              <p class="describe">1150 x 900 x 500</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, ENCRYPTED_STRING } from '@/store/mutation-types'
export default {
  components: {},
  data() {
    return {
      active1: '1',
      active3: '1',
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    onclick1(str) {
      this.active1 = str
    },
    onclick2(str) {
      this.active3 = str
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  display: contents;
  height: 100%;
  width: 100%;
  background: #fff !important;
  .banner {
    .img {
      max-height: 998px;
      width: 100%;
      max-width: 1920px;
    }
  }
  .content2 {
    text-align: center;
    height: 940px;
    background: #fbfbfb;
    border-radius: 0px 0px 0px 0px;
    .title {
      width: 551px;
      height: 181px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
      padding-top: 103px;
      text-align: center;
      margin: 0 auto;
    }
    .img {
      margin-top: 89px;
      width: 269px;
      height: 589px;
    }
    .gzBox {
      width: 1200px;
      margin: 0 auto;
      padding-top: 127px;
      position: relative;
      .gz_action {
        position: absolute;
        right: 100px;
        top: 20px;
        .bk {
          width: 30px;
          height: 20px;
        }
        ul {
          width: 200px;
          display: flex;
          justify-content: space-between;
          li {
            width: 30px;
            height: 40px;
            cursor: pointer;
            .txt {
              margin-top: 5px;
            }
          }
        }
      }
      .gz {
        margin: 0 auto;
        height: 610px;
        width: 281px;
        background: #e9e9e9;
        padding: 20px 10px 10px 10px;
        ul {
          background: #ffffff;
          height: 564px;
          padding: 0;
          margin: 0;
          border: 2px solid #ccc;
          li:first-child {
            margin-top: 0;
          }
          li {
            display: flex;
            margin-top: 2px;
            .left {
              margin-right: 1px;
            }
            .left,
            .right {
              position: relative;
              width: 130px;
              height: 68px;
              .number {
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 20px;
                background: #fff;
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
        .footer {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin: 8px 2px;
          .line {
            width: 7px;
            height: 7px;
            background: black;
            border-radius: 50%;
          }
        }
      }
    }
    .footer_bottom {
      margin: 0 auto;
      width: 281px;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .line_bottom {
        background: #ccc;
        width: 25px;
        height: 4px;
      }
    }
  }
  .content3 {
    height: 1070px;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    .title {
      padding-top: 65px;
      min-width: 423px;
      margin: 0 auto;
      height: 143px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
    .img {
      margin-top: 52px;
    }
    .action {
      width: 1100px;
      margin: 106px auto 0 auto;
      ul {
        display: flex;
        justify-content: space-between;
        li {
          cursor: pointer;
          width: 345px;
          height: 140px;
          border-radius: 8px 8px 8px 8px;
          //opacity: 0.39;
          border: 1px solid #a7a7a7;
          display: flex;
          align-items: center;
          justify-content: center;
          .img_logo1 {
            height: 70px !important;
            .cabinetBox1 {
              height: 55px !important;
            }
          }
          .desc {
            .title3 {
              min-width: 94px;
              height: 33px;
              font-size: 24px;
              font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
              font-weight: normal;
              color: #000000;
            }
            .describe {
              min-width: 124px;
              height: 22px;
              font-size: 16px;
              font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
              font-weight: normal;
              color: #a7a7a7;
            }

            p {
              margin-bottom: 0px;
            }
          }
          .img_logo {
            height: 100px;
            width: 100px;
            line-height: 100px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .cabinetBox {
              overflow: hidden;

              height: 90px;
              width: 59px;
              border: 1px solid #b5f0e7;
              line-height: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .row {
                height: 10px;
                width: 50px;
                margin-top: 2px;
                display: flex;
                .left {
                  margin-right: 5px;
                }
                .left,
                .right {
                  height: 100%;
                  width: 30px;
                }
              }
              .row1 {
                height: 12px;
                width: 50px;
                margin-top: 2px;
                display: flex;
                .left {
                  margin-right: 5px;
                }
                .left,
                .right {
                  height: 100%;
                  width: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.bk1 {
  background: #18c6ff;
}
.bk3 {
  background: #1addc8;
}
.bk2 {
  background: #f2f2f2;
}
.c1 {
  color: #18c6ff !important;
}
.c2 {
  color: #1addc8 !important;
}
.c3 {
  color: #ccc !important;
}
ul li {
  list-style: none;
}
</style>