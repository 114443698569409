var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index1" }, [
    _vm._m(0),
    _vm._m(1),
    _vm._m(2),
    _c("div", { staticClass: "banner4" }, [
      _c("div", { staticClass: "slogan" }, [
        _vm._v("使用毅力实验通可以实现如下的便捷")
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "left" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: _vm.active == 1 ? " active" : "",
                on: {
                  click: function($event) {
                    return _vm.onClick4(1)
                  }
                }
              },
              [
                _vm._m(3),
                _vm.active != 1
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("../../../assets/newIndex/box1.png")
                      }
                    })
                  : _vm._e(),
                _vm.active == 1
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("../../../assets/newIndex/box2.png")
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active == 2 ? " active" : "",
                on: {
                  click: function($event) {
                    return _vm.onClick4(2)
                  }
                }
              },
              [
                _vm._m(4),
                _vm.active != 2
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("../../../assets/newIndex/box1.png")
                      }
                    })
                  : _vm._e(),
                _vm.active == 2
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("../../../assets/newIndex/box2.png")
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "li",
              {
                class: _vm.active == 3 ? " active" : "",
                on: {
                  click: function($event) {
                    return _vm.onClick4(3)
                  }
                }
              },
              [
                _vm._m(5),
                _vm.active != 3
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("../../../assets/newIndex/box1.png")
                      }
                    })
                  : _vm._e(),
                _vm.active == 3
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("../../../assets/newIndex/box2.png")
                      }
                    })
                  : _vm._e()
              ]
            )
          ])
        ]),
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner4_right.png") }
        })
      ])
    ]),
    _c("div", { staticClass: "banner5" }, [
      _c("div", { staticClass: "slogan" }, [_vm._v("毅力实验通的三大优势")]),
      _c("div", { staticClass: "describe" }, [
        _vm._v("数据上云、安装方便、贴合实验室场景")
      ]),
      _c("div", { staticClass: "box" }, [
        _vm.active1 == 1
          ? _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("实验室数据上云，助力实验室管理数字化智慧化")
              ]),
              _vm._m(6)
            ])
          : _vm._e(),
        _vm.active1 == 2
          ? _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("摆脱网络条件束缚，项目现场实施更方便")
              ]),
              _vm._m(7)
            ])
          : _vm._e(),
        _vm.active1 == 3
          ? _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("一套管理系统无限智能接入")
              ]),
              _vm._m(8)
            ])
          : _vm._e(),
        _c("div", { staticClass: "right" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: _vm.active1 == 1 ? "active5" : "",
                on: {
                  click: function($event) {
                    return _vm.onClick5(1)
                  }
                }
              },
              [_vm._v("实验室数据上云")]
            ),
            _c(
              "li",
              {
                class: _vm.active1 == 2 ? "active5" : "",
                on: {
                  click: function($event) {
                    return _vm.onClick5(2)
                  }
                }
              },
              [_vm._v("无需网络")]
            ),
            _c(
              "li",
              {
                class: _vm.active1 == 3 ? "active5" : "",
                on: {
                  click: function($event) {
                    return _vm.onClick5(3)
                  }
                }
              },
              [_vm._v("智能接入")]
            ),
            _c(
              "li",
              { staticClass: "btn" },
              [
                _c(
                  "a-button",
                  {
                    staticClass: "demonstrate-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.yuyue }
                  },
                  [
                    _c("img", {
                      staticClass: "demonstrate",
                      attrs: {
                        src: require("../../../assets/newIndex/demonstrate.png"),
                        alt: "logo"
                      }
                    }),
                    _vm._v("预约演示")
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner1" }, [
      _c("div", { staticClass: "slogan" }, [
        _vm._v("实验设备管理自助借还解决方案")
      ]),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../../assets/newIndex/banner1_logo.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner2" }, [
      _c("div", { staticClass: "slogan" }, [
        _vm._v("元器件与低值易耗品设备借用管理解决方案")
      ]),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../../assets/newIndex/banner2_logo.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner3" }, [
      _c("div", { staticClass: "slogan" }, [
        _vm._v("采用毅力实验通小程序可以实现如下的便捷")
      ]),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("../../../assets/newIndex/banner3_logo.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left2" }, [
      _c("div", { staticClass: "titleBox" }, [_vm._v("批量上传登记入库")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left2" }, [
      _c("div", { staticClass: "titleBox" }, [_vm._v("设备存入智能柜")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left2" }, [
      _c("div", { staticClass: "titleBox" }, [_vm._v("下载借用领用元器件记录")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [
          _vm._v("与常见的工控机智能柜相比，")
        ])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [_vm._v("实验室数据非本地存储")])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [_vm._v("全部实时上云")])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [_vm._v("无需靠近柜机操作")])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [
          _vm._v("使用更便捷，数据更实时")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [
          _vm._v("只需要一跟电源线即可实现数据联网")
        ])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [_vm._v("无需其他网络视适配")])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [_vm._v("现场安装调试更方便")])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [
          _vm._v("后期增加柜机、异地搬迁无阻碍")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [
          _vm._v("相较于RFID智能柜，本产品性价比高")
        ])
      ]),
      _c("li", [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("../../../assets/newIndex/banner5_icon.png") }
        }),
        _c("p", { staticClass: "describe5" }, [
          _vm._v("复购增加柜机台数节约采购成本")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }