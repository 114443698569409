<template>
  <div class="index1">
    <div class="banner1">
      <div class="slogan">实验设备管理自助借还解决方案</div>
      <img src="../../../assets/newIndex/banner1_logo.png" class="img" />
    </div>
    <div class="banner2">
      <div class="slogan">元器件与低值易耗品设备借用管理解决方案</div>
      <img src="../../../assets/newIndex/banner2_logo.png" class="img" />
    </div>
    <div class="banner3">
      <div class="slogan">采用毅力实验通小程序可以实现如下的便捷</div>
      <img src="../../../assets/newIndex/banner3_logo.png" class="img" />
    </div>
    <div class="banner4">
      <div class="slogan">使用毅力实验通可以实现如下的便捷</div>
      <div class="box">
        <div class="left">
          <ul>
            <li @click="onClick4(1)" :class="active == 1 ? ' active' : ''">
              <div class="left2">
                <div class="titleBox">批量上传登记入库</div>
                <!-- <div class="titledescribe">副标题副标题副标题副标题副标题副标题 副标题副标题副标题副标题</div> -->
              </div>
              <img v-if="active != 1" src="../../../assets/newIndex/box1.png" class="img" />
              <img v-if="active == 1" src="../../../assets/newIndex/box2.png" class="img" />
            </li>
            <li @click="onClick4(2)" :class="active == 2 ? ' active' : ''">
              <div class="left2">
                <div class="titleBox">设备存入智能柜</div>
                <!-- <div class="titledescribe">副标题副标题副标题副标题副标题副标题 副标题副标题副标题副标题</div> -->
              </div>
              <img v-if="active != 2" src="../../../assets/newIndex/box1.png" class="img" />
              <img v-if="active == 2" src="../../../assets/newIndex/box2.png" class="img" />
            </li>
            <li @click="onClick4(3)" :class="active == 3 ? ' active' : ''">
              <div class="left2">
                <div class="titleBox">下载借用领用元器件记录</div>
                <!-- <div class="titledescribe">副标题副标题副标题副标题副标题副标题 副标题副标题副标题副标题</div> -->
              </div>
              <img v-if="active != 3" src="../../../assets/newIndex/box1.png" class="img" />
              <img v-if="active == 3" src="../../../assets/newIndex/box2.png" class="img" />
            </li>
          </ul>
        </div>
        <img src="../../../assets/newIndex/banner4_right.png" class="img" />
      </div>
    </div>
    <div class="banner5">
      <div class="slogan">毅力实验通的三大优势</div>
      <div class="describe">数据上云、安装方便、贴合实验室场景</div>
      <div class="box">
        <div class="left" v-if="active1 == 1">
          <div class="title">实验室数据上云，助力实验室管理数字化智慧化</div>
          <ul>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">与常见的工控机智能柜相比，</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">实验室数据非本地存储</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">全部实时上云</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">无需靠近柜机操作</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">使用更便捷，数据更实时</p>
            </li>
          </ul>
        </div>
        <div class="left" v-if="active1 == 2">
          <div class="title">摆脱网络条件束缚，项目现场实施更方便</div>
          <ul>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">只需要一跟电源线即可实现数据联网</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">无需其他网络视适配</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">现场安装调试更方便</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">后期增加柜机、异地搬迁无阻碍</p>
            </li>
          </ul>
        </div>
        <div class="left" v-if="active1 == 3">
          <div class="title">一套管理系统无限智能接入</div>
          <ul>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">相较于RFID智能柜，本产品性价比高</p>
            </li>
            <li>
              <img src="../../../assets/newIndex/banner5_icon.png" class="img" />
              <p class="describe5">复购增加柜机台数节约采购成本</p>
            </li>
          </ul>
        </div>
        <div class="right">
          <ul>
            <li @click="onClick5(1)" :class="active1 == 1 ? 'active5' : ''">实验室数据上云</li>
            <li @click="onClick5(2)" :class="active1 == 2 ? 'active5' : ''">无需网络</li>
            <li @click="onClick5(3)" :class="active1 == 3 ? 'active5' : ''">智能接入</li>
            <li class="btn">
              <a-button type="primary" @click="yuyue" class="demonstrate-btn"
                ><img class="demonstrate" src="../../../assets/newIndex/demonstrate.png" alt="logo" />预约演示</a-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  components: {},
  data() {
    return {
      active3Child: '1',
      active: '',
      active1: '1',
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    onClick4(str) {
      this.active = str
    },
    onClick5(str) {
      this.active1 = str
    },
    yuyue() {
      this.$parent.handleTabClick('yy')
    },
  },
}
</script>
<style lang="less" scoped>
.index1 {
  display: contents;
  .banner1 {
    width: 1200px;
    height: 998px;
    margin: 0 auto;
    .slogan {
      text-align: center;
      padding-top: 95px;
      min-width: 706px;
      height: 173px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
    .describe {
      text-align: center;
      min-width: 483px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #666666;
    }
    .img {
      margin-top: 104px;
      width: 1200px;
    }
  }
  .banner2 {
    width: 100%;
    height: 845px;
    text-align: center;
    background: #faf9fd;
    margin: 0 auto;
    .slogan {
      text-align: center;
      padding-top: 95px;
      min-width: 706px;
      height: 173px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
    .describe {
      text-align: center;
      min-width: 483px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #666666;
    }
    .img {
      margin-top: 104px;
      width: 1300px;
      height: 536px;
    }
  }
  .banner3 {
    width: 1200px;
    text-align: center;
    height: 975px;
    margin: 0 auto;
    .slogan {
      text-align: center;
      padding-top: 113px;
      min-width: 706px;
      height: 191px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
    .describe {
      text-align: center;
      min-width: 483px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #666666;
    }
    .img {
      margin-top: 80px;
      width: 1200px;
    }
  }
  .banner3 {
    width: 1200px;
    text-align: center;
    height: 975px;
    margin: 0 auto;
    .slogan {
      text-align: center;
      padding-top: 113px;
      min-width: 706px;
      height: 191px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
    .describe {
      text-align: center;
      min-width: 483px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #666666;
    }
    .img {
      margin-top: 80px;
      width: 1200px;
    }
  }
  .banner4 {
    //background: red;
    background: url('../../../assets/newIndex/banner4_bk.png');
    background-size: cover;
    width: 100%;
    height: 875px;
    max-width: 1920px;
    background-repeat: no-repeat;
    text-align: center;
    margin: 0 auto;
    .slogan {
      text-align: center;
      padding-top: 113px;
      min-width: 706px;
      height: 191px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
    }
    .describe {
      text-align: center;
      min-width: 483px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #666666;
    }
    .box {
      width: 1200px;
      margin: 83px auto 0 auto;
      display: flex;
      justify-content: space-between;
      .left {
        ul {
          padding: 0;
          .active {
            .titleBox {
              color: #fff !important;
            }
            .titledescribe {
              color: #fff !important;
            }
            background: linear-gradient(316deg, #14d0b4 0%, #66ddc1 100%) !important;
          }
          li {
            .img {
              width: 37px;
              height: 37px;
              margin-top: 20px;
            }
            padding: 22px 28px 21px 27px;
            width: 442px;
            height: 115px;
            background: #fff;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            .titleBox {
              width: 227px;
              height: 28px;
              font-size: 20px;
              font-family: Alibaba PuHuiTi -62 Medium, Alibaba PuHuiTi 30;
              font-weight: normal;
              color: #000000;
              line-height: 22px;
              text-align: left;
            }
            .titledescribe {
              text-align: left;
              margin-top: 5px;
              width: 248px;
              height: 39px;
              font-size: 14px;
              font-family: Alibaba PuHuiTi -42 Light, Alibaba PuHuiTi 30;
              font-weight: normal;
              color: #999999;
              line-height: 19px;
            }
          }
        }
      }
      .right {
        width: 713px;
        height: 394px;
      }
    }
  }
  .banner5 {
    //background: red;
    background: url('../../../assets/newIndex/banner5_bk.png');
    background-size: cover;
    width: 100%;
    height: 875px;
    max-width: 1920px;
    background-repeat: no-repeat;
    text-align: center;
    margin: 0 auto;
    .slogan {
      text-align: center;
      padding-top: 113px;
      min-width: 950px;
      height: 191px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #ffffff;
    }
    .describe {
      text-align: center;
      min-width: 483px;
      height: 31px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #ffffff;
    }
    .box {
      width: 1200px;
      margin: 103px auto 0 auto;
      display: flex;
      justify-content: space-between;
      .left {
        .title {
          min-width: 390px;
          height: 30px;
          font-size: 22px;
          font-family: Alibaba PuHuiTi -52 Regular, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
          /* line-height: 71px; */
          text-align: left;
          margin-bottom: 35px;
        }
        width: 887px;
        height: 308px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        padding-top: 49px;
        padding-left: 58px;
        .slogan {
          min-width: 390px;
          height: 30px;
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
          line-height: 71px;
        }
        ul {
          padding: 0;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .img {
              width: 15px;
              height: 15px;
              margin-right: 11px;
            }
            .describe5 {
              min-width: 296px;
              /* height: 18px; */
              font-size: 16px;
              font-family: Alibaba PuHuiTi -52 Regular, Alibaba PuHuiTi 30;
              font-weight: normal;
              color: #666666;
              margin: 0;
              text-align: left;
            }
          }
        }
      }
      .right {
        width: 306px;
        ul {
          padding: 0;
          .active5 {
            background: #5fdcc4;
            border-right: 3px solid #fff;
            color: #fff;
          }
          .btn {
            display: flex;
            justify-content: end;
          }
          li {
            cursor: pointer;
            min-width: 138px;
            min-height: 28px;
            font-size: 20px;
            /* background: #f5f5f5; */
            font-family: Alibaba PuHuiTi -42 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #d0f5ee;
            /* line-height: 71px; */
            padding: 16px 27px 16px 0px;
            text-align: right;

            .demonstrate-btn {
              display: flex;
              -ms-flex-pack: center;
              justify-content: center;
              -ms-flex-align: center;
              align-items: center;
              background: #eb4d27;
              margin-top: 55px;
              width: 131px;
              height: 50px;
              .demonstrate {
                width: 12.5px;
                height: 12.5px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
ul li {
  list-style: none;
}
</style>