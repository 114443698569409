var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "学生姓名",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "name"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入学生姓名" },
                            model: {
                              value: _vm.model.name,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "学生编号",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "code"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入学生编号" },
                            model: {
                              value: _vm.model.code,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "code", $$v)
                              },
                              expression: "model.code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "性别",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "gender"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "sex",
                              placeholder: "请选择性别"
                            },
                            model: {
                              value: _vm.model.gender,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "gender", $$v)
                              },
                              expression: "model.gender"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "身份证号",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "idNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入身份证号" },
                            model: {
                              value: _vm.model.idNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "idNumber", $$v)
                              },
                              expression: "model.idNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "手机号",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "phone"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.model.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "phone", $$v)
                              },
                              expression: "model.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "生日",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "birthday"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择生日" },
                            model: {
                              value: _vm.model.birthday,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "birthday", $$v)
                              },
                              expression: "model.birthday"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "班级",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "classId"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "tb_class where status=1,name,id",
                              placeholder: "请选择班级"
                            },
                            model: {
                              value: _vm.model.classId,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "classId", $$v)
                              },
                              expression: "model.classId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "status"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "status",
                              placeholder: "请选择状态"
                            },
                            model: {
                              value: _vm.model.status,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "status", $$v)
                              },
                              expression: "model.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }